// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {DateInput, Icon, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const SIZE = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
};

const getWidth = ({size, isFullWidth}: any) => {
  if (isFullWidth) {
    return {width: '100%'};
  }
  if (size === SIZE.SMALL) {
    return {
      width: 92,
    };
  }
  if (size === SIZE.MEDIUM) {
    return {
      width: 103,
    };
  }
  return {
    width: 120,
  };
};

const getSizeStyle = ({size, isFullWidth}: any) => {
  const width = getWidth({size, isFullWidth});
  if (size === SIZE.SMALL) {
    return {
      ...width,
      height: 24,
      minHeight: 24,
      fontSize: 12,
      paddingLeft: 22,
    };
  }
  if (size === SIZE.MEDIUM) {
    return {
      ...width,
      height: 28,
      minHeight: 28,
      paddingLeft: 22,
    };
  }
  return {
    ...width,
    paddingLeft: 28,
  };
};

const LABEL_OFFSET = 24;

const getTopPosition = ({defaultPosition, label, size, responsive}: any) => {
  const responsiveOffset = responsive.desktop || size === SIZE.SMALL ? 0 : 14;
  if (label) {
    return defaultPosition + LABEL_OFFSET + responsiveOffset;
  }
  return defaultPosition + responsiveOffset;
};

const getIconPositionStyle = ({size, label, responsive}: any) => {
  if (size === SIZE.SMALL) {
    return {
      top: getTopPosition({defaultPosition: 5, label, size, responsive}),
      left: 4,
    };
  }
  if (size === SIZE.MEDIUM) {
    return {
      top: getTopPosition({defaultPosition: 7, label, responsive}),
      left: 6,
    };
  }
  return {
    top: getTopPosition({defaultPosition: 11, label, responsive}),
    left: 10,
  };
};

const Container = Styled.View`
  z-index: ${({index}: any) => 100 - index};
`;

const DateField = ({
  form,
  field,
  label,
  disabled,
  required,
  hasWarning,
  warningMessage,
  onChangeDate,
  isClearable,
  isPortaled,
  index,
  size,
  style,
  isFullWidth,
  iconStyle = {},
}: any) => {
  const responsive = useResponsive();
  const sizeStyle = getSizeStyle({size, isFullWidth});
  const iconPositionStyle = getIconPositionStyle({size, label, responsive});
  return (
    <Container index={index} style={isFullWidth ? {flex: 1} : null}>
      <Icon
        source={Icon.Calendar}
        size={13}
        color={colors.gray.tertiary}
        style={{
          position: 'absolute',
          zIndex: 101, // The default zIndex for FieldInput is 100
          ...iconPositionStyle,
          ...iconStyle,
        }}
      />
      <FieldInput
        {...form}
        component={DateInput}
        name={field}
        label={label}
        hasWarning={hasWarning}
        warningMessage={warningMessage}
        isClearable={isClearable}
        isResponsive
        input={{
          disabled,
          required,
          placeholder: 'MM/DD/YY',
          setFieldValue: form.setFieldValue,
          onChangeDate,
          isPortaled,
          style: {
            paddingRight: 0,
            ...sizeStyle,
            ...style,
          },
        }}
      />
    </Container>
  );
};

const Small = ({...props}) => {
  // @ts-expect-error TS(2739): Type '{ size: string; }' is missing the following ... Remove this comment to see the full error message
  return <DateField {...props} size={SIZE.SMALL} />;
};

const Medium = ({...props}) => {
  // @ts-expect-error TS(2739): Type '{ size: string; }' is missing the following ... Remove this comment to see the full error message
  return <DateField {...props} size={SIZE.MEDIUM} />;
};

DateField.Small = Small;
DateField.Medium = Medium;
DateField.SIZE = SIZE;

// --------------------------------------------------
// Props
// --------------------------------------------------
DateField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hasWarning: PropTypes.bool,
  warningMessage: PropTypes.string,
  onChangeDate: PropTypes.func,
  isClearable: PropTypes.bool,
  isPortaled: PropTypes.bool,
  index: PropTypes.number,
  size: PropTypes.string,
  style: PropTypes.object,
  isFullWidth: PropTypes.bool,
  isHoverEffectEnabled: PropTypes.bool,
  iconStyle: PropTypes.object,
};

DateField.defaultProps = {
  label: null,
  disabled: false,
  required: false,
  hasWarning: false,
  warningMessage: null,
  onChangeDate: () => {},
  isClearable: false,
  isPortaled: false,
  index: 0,
  size: SIZE.LARGE,
  style: {},
  isFullWidth: false,
  isHoverEffectEnabled: true,
};

export default DateField;
