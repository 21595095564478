// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  usePagination,
  useQuery,
  useResponsive,
  useState,
  useNavigation,
  useHover,
} from '@supermove/hooks';
import {SupermoveProductModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import EmptyState from '@shared/design/components/EmptyState';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import CompaniesListPageHeader from 'modules/Company/components/CompaniesListPageHeader';

const Card = Styled.View`
  flex-direction: column;
  padding-vertical: 8px;
  background-color: ${({isHovered}: {isHovered: boolean}) =>
    isHovered ? colors.blue.accent : colors.white};
`;

const RowContainer = Styled.Touchable`
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const MicroLabelText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const Line = Styled.View`
  width: 100%;
  height: 1px;
  background-color: ${colors.gray.border};
`;

const EmptyStateContainer = Styled.View`
  height: 200px;
  justify-content: center;
  align-items: center;
`;

interface CompanyData {
  id: string;
  name: string;
  slug: string;
  organizations: [
    {
      id: string;
    },
  ];
  activeSupermoveProducts: SupermoveProductModel[];
}

interface ResponsiveType {
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
}

const SupermoveProductBadges = ({
  supermoveProducts,
}: {
  supermoveProducts: SupermoveProductModel[];
}) => {
  const sortedSupermoveProductKinds = SupermoveProductKind.getSortedKinds();

  return (
    <Row style={{flexWrap: 'wrap'}}>
      {supermoveProducts
        .sort(
          (a, b) =>
            sortedSupermoveProductKinds.indexOf(a.kind) -
            sortedSupermoveProductKinds.indexOf(b.kind),
        )
        .map(({kind}: {kind: string}, index: number) => (
          <Row key={index}>
            <Badge
              isResponsive
              label={SupermoveProductKind.getLabel(kind)}
              color={SupermoveProductKind.getColor(kind)}
            />
            <Space width={8} />
          </Row>
        ))}
    </Row>
  );
};

const CompanyListHeader = ({responsive}: {responsive: ResponsiveType}) => {
  return (
    <Row
      style={{
        paddingHorizontal: 24,
        paddingVertical: 8,
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: colors.gray.border,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
      }}
    >
      <Row style={{flex: 2}}>
        <TableHeaderText responsive={responsive}>Company Name</TableHeaderText>
      </Row>
      <Space width={16} />
      <Row style={{flex: 2}}>
        <TableHeaderText responsive={responsive}>Identifier</TableHeaderText>
      </Row>
      <Space width={16} />
      <Row style={{flex: 1}}>
        <TableHeaderText responsive={responsive}>Branches</TableHeaderText>
      </Row>
      <Space width={16} />
      <Row style={{flex: 3}}>
        <TableHeaderText responsive={responsive}>Products</TableHeaderText>
      </Row>
    </Row>
  );
};

// Without React.memo, the FlatList re-renders all items and performance is impacted
const CompanyCard = React.memo(
  ({
    company,
    responsive,
    isLast,
  }: {
    company: CompanyData;
    responsive: ResponsiveType;
    isLast: boolean;
  }) => {
    const {ref, isHovered} = useHover();
    return (
      <Card
        ref={ref}
        isHovered={isHovered}
        style={{
          flexDirection: responsive.desktop ? 'row' : 'column',
          paddingHorizontal: responsive.desktop ? 24 : 16,
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderColor: colors.gray.border,
          borderBottomRightRadius: responsive.desktop && isLast ? 4 : 0,
          borderBottomLeftRadius: responsive.desktop && isLast ? 4 : 0,
          borderBottomWidth: 1,
          overflow: 'hidden',
        }}
      >
        <Row style={{flex: responsive.desktop ? 2 : 1}}>
          <LabelText responsive={responsive}>{company.name}</LabelText>
        </Row>
        <Space height={8} width={16} />
        <Row style={{flex: responsive.desktop ? 2 : 1}}>
          {!responsive.desktop && (
            <React.Fragment>
              <MicroLabelText responsive={responsive}>Identifier:</MicroLabelText>
              <Space width={4} />
            </React.Fragment>
          )}
          <MicroText responsive={responsive}>{company.slug}</MicroText>
        </Row>
        <Space height={8} width={16} />
        <Row style={{flex: 1}}>
          {!responsive.desktop && (
            <React.Fragment>
              <MicroLabelText responsive={responsive}>Number of Branches:</MicroLabelText>
              <Space width={4} />
            </React.Fragment>
          )}
          <MicroText responsive={responsive}>{company.organizations.length}</MicroText>
        </Row>
        <Space height={8} width={16} />
        <Row style={{flex: responsive.desktop ? 3 : 1}}>
          <SupermoveProductBadges supermoveProducts={company.activeSupermoveProducts} />
        </Row>
      </Card>
    );
  },
  (prevProps, nextProps) =>
    prevProps.company.id === nextProps.company.id &&
    prevProps.company.name === nextProps.company.name &&
    prevProps.company.slug === nextProps.company.slug &&
    prevProps.company.organizations.length === nextProps.company.organizations.length &&
    (prevProps.company.activeSupermoveProducts.length ===
      nextProps.company.activeSupermoveProducts.length) ===
      _.isEqual(prevProps.responsive, nextProps.responsive),
);

const NoCompanies = () => {
  return (
    <EmptyStateContainer>
      <EmptyState title={'No results.'} message={'Clear your search and try again.'} />
    </EmptyStateContainer>
  );
};

const CompaniesListPage = () => {
  const responsive = useResponsive();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const {navigator} = useNavigation();
  const {loading, data, refetch} = useQuery(CompaniesListPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        page: currentPage,
        resultsPerPage: 50,
      },
      searchQuery,
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page: number) => {
      setCurrentPage(page);
    },
  });

  const handleSearch = (query: string) => {
    setCurrentPage(1);
    setSearchQuery(query);
  };

  const handleNavigateToCompanyDetails = (slug: string) => {
    if (responsive.desktop) {
      // TODO(Hammad) Ideally we'd use the CompanyRootPage, but there is a back navigation bug that isn't solved by using replace.
      navigator.navigate('CompanyDetailsOverviewPage', {slug});
    } else {
      navigator.navigate('CompanyDetailsPageMobileNavigation', {slug});
    }
  };

  const isInitialLoad = loading && !data;

  return (
    <AdminAppPage
      title={'Companies'}
      HeaderElement={
        isInitialLoad ? null : (
          <CompaniesListPageHeader
            searchQuery={searchQuery}
            handleSearch={handleSearch}
            refetch={refetch}
          />
        )
      }
    >
      <Loading loading={loading} as={PageLoadingIndicator} alwaysUpdate>
        {() => (
          <React.Fragment>
            <FlatList
              data={data.paginatedList.companies}
              keyExtractor={(company: CompanyData) => company.id}
              renderItem={({item: company, index}: {item: CompanyData; index: number}) => (
                <RowContainer onPress={() => handleNavigateToCompanyDetails(company.slug)}>
                  <CompanyCard
                    company={company}
                    responsive={responsive}
                    isLast={index === data.paginatedList.companies.length - 1}
                  />
                </RowContainer>
              )}
              ItemSeparatorComponent={Line}
              ListHeaderComponent={() =>
                responsive.desktop ? (
                  <React.Fragment>
                    <Space height={24} />
                    <CompanyListHeader responsive={responsive} />
                  </React.Fragment>
                ) : null
              }
              ListFooterComponent={() => (responsive.desktop ? <Space height={24} /> : null)}
              ListEmptyComponent={NoCompanies}
              initialNumToRender={50}
              contentContainerStyle={
                responsive.desktop
                  ? {
                      maxWidth: 1200,
                      width: '100%',
                      paddingHorizontal: 24,
                      flex: 1,
                      alignSelf: 'center',
                    }
                  : {}
              }
            />
            <Space height={24} />
            <PaginationBar pagination={pagination} />
            <Space height={24} />
          </React.Fragment>
        )}
      </Loading>
    </AdminAppPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompaniesListPage.query = gql`
  ${usePagination.fragment}

  query CompaniesListPage(
    $searchQuery: String
    $pagination: PaginationInput!
  ) {
    ${gql.query}
    viewer {
      id
    }
    paginatedList: filteredCompaniesPaginatedList(
      searchQuery: $searchQuery
      pagination: $pagination
    ) {
      companies: results {
        id
        name
        slug
        organizations {
          id
        }
        activeSupermoveProducts {
          id
          kind
        }
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

export default CompaniesListPage;
